import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect, useState } from "react";
import Modal1 from "react-modal";
import "./Modal.css";
import axios from "axios";
import { ENDPOINT } from "../GlobalVariables";

const customStyles = {
  content: {
    borderRadius: "10px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    marginTop: "20px",
    zIndex: "1",
  },
};

const Modal = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [popupImage, setPopupImage] = useState(null);
  useEffect(() => {
    const fetchItems = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(`${ENDPOINT}/api/v1/popup-carousel`);
        setPopupImage(data.find((p) => p.type === "popup"));
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    };
    fetchItems();
    setIsOpen(true);
  }, []);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal1
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      // contentLabel="Example Modal"
    >
      <div onClick={closeModal} className="modal__close__main__div">
        <h6 className="mb-0"> Heads up!</h6>{" "}
        <ClearIcon onClick={closeModal} className="modal__close__main" />
      </div>
      {loading ? (
        "Please wait..."
      ) : popupImage.pic ? (
        <img
          alt="Popup"
          src={popupImage.pic}
          style={{ width: 400, height: "400px" }}
        />
      ) : (
        ""
      )}

      <div onClick={closeModal} className="text-center mt-2 cursor-pointer">
        <h6 className="mb-0 text-danger mt-1"> Click here to close</h6>{" "}
      </div>
    </Modal1>
  );
};

export default Modal;
