import axios from "axios";
import React, { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import { ENDPOINT } from "../GlobalVariables";
import Loader from "../Loader";
import "./Dashboard.css";

const UploadPopupAndCarouselImage = ({
  poupAndCarouselImage,
  setPopupAndCarouselImage,
}) => {
  const [type, setType] = useState("popup");
  const [loading, setLoading] = useState(false);
  const [pic, setPic] = useState("");
  const [picLoader, setPicLoader] = useState(false);

  const postImage = (pics) => {
    if (pics === undefined) {
      toast.error("Invalid Image");
      return;
    }
    setPicLoader(true);
    const data = new FormData();
    data.append("file", pics);

    data.append("upload_preset", "zfwzbdlg");

    data.append("cloud_name", "dpwgvr1b7");

    fetch("https://api.cloudinary.com/v1_1/dpwgvr1b7/image/upload", {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        setPic(data.url.toString());
        setPicLoader(false);
      })
      .catch((err) => {
        setPicLoader(false);
      });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (!pic) {
      toast.error("Fields Are Empty");
      setLoading(false);
    } else {
      setLoading(true);

      try {
        setLoading(true);
        const { data } = await axios.post(`${ENDPOINT}/api/v1/popup-carousel`, {
          pic,
          type: "popup",
        });
        toast.success("Faculty member is successfully posted");
        setPic(null);
        setLoading(false);
        setPopupAndCarouselImage([...poupAndCarouselImage, data]);
      } catch (error) {
        setLoading(false);
      }
    }
  };
  return (
    <>
      {loading && <Loader />}
      {picLoader && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <h1 className="text-xl flex justify-center">Pic Is Loading...</h1>

          <TailSpin color="#000" height={30} width={30} timeout={2500} />
        </div>
      )}
      <section className=" main__secction1">
        <div className="w-1/1 flex  overflow-hidden ">
          <div
            className="
        flex flex-col
        w-2/4
      
        flex-1
        px-4
        
        overflow-hidden
        sm:px-6
        lg:flex-none lg:px-20
        xl:px-24
        main__secction
      "
            style={{ width: "100%" }}
          >
            <div className="w-1/1 max-w-xl mx-auto lg:w-1/1 ">
              <div></div>
              <div>
                <div>
                  <form>
                    {/* Input File */}
                    <div class="mb-3">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-neutral-600"
                      >
                        {" "}
                        Choose Image{" "}
                      </label>
                      <input
                        class="form-control"
                        type="file"
                        id="formFile"
                        accept="image/*"
                        disabled={poupAndCarouselImage.find(
                          (p) => p.type === "popup"
                        )}
                        onChange={(e) => postImage(e.target.files[0])}
                        style={{ marginTop: "6px" }}
                      />
                    </div>

                    {/* <div className="inline-block relative w-64">
                      <label className="block text-sm font-medium text-neutral-600">
                        Choose Type{" "}
                      </label>
                      <select
                        onChange={(e) => setType(e.target.value)}
                        className="contact__input1__main  w-full   px-4 py-3 pr-8 mt-1 mb-1 "
                      >
                        <option value="popup">Popup</option>
                        <option value="carousel">Carousel</option>
                      </select>
                    </div> */}

                    <div>
                      <button
                        className=" bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg contact__us__main__button mt-4"
                        onClick={handleSubmit}
                        disabled={pic === "" ? true : false}
                      >
                        {picLoader ? (
                          <TailSpin
                            color="#000"
                            height={20}
                            width={20}
                            timeout={2500}
                          />
                        ) : (
                          "Upload"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UploadPopupAndCarouselImage;
