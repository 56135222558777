import DeleteIcon from "@mui/icons-material/Delete";
import { Tooltip } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ENDPOINT } from "../../GlobalVariables";
import Loader from "../../Loader";

const PopupAndcarouselImageTable = ({
  poupAndCarouselImage,
  setPopupAndCarouselImage,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(`${ENDPOINT}/api/v1/popup-carousel`);
        setLoading(true);
        setPopupAndCarouselImage(data);
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    };
    fetchItems();
  }, []);

  const deletePopupAndCarousel = async (id) => {
    try {
      const { data } = await axios.delete(
        `${ENDPOINT}/api/v1/popup-carousel/${id}`
      );
      setPopupAndCarouselImage(
        poupAndCarouselImage.filter((f) => f._id !== id)
      );
      toast.success("Image Deleted Successfully");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <div style={{ overflowX: "auto" }}>
        <table className="table main__secction1" style={{ overflowX: "auto" }}>
          <thead>
            <tr>
              <th scope="col">SN</th>
              <th scope="col">Image</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {loading && <Loader />}
            {poupAndCarouselImage
              ? poupAndCarouselImage.length === 0 && (
                  <h6 style={{ marginTop: "10px" }}>No Popup Images</h6>
                )
              : null}
            {poupAndCarouselImage.map((value, index) => {
              return (
                <tr key={value._id}>
                  <td>{index + 1}</td>{" "}
                  <td>
                    <div className="bg-cyan-300">
                      <img
                        src={value.pic}
                        className="object-scale-down h-10 w-auto"
                        alt="popupAndCarousel"
                      />
                    </div>
                  </td>
                  <td>
                    {" "}
                    <button>
                      {" "}
                      <Tooltip title="Delete">
                        <DeleteIcon
                          fontSize="small"
                          style={{ color: "#d11a2a", marginLeft: "15px" }}
                          onClick={() => deletePopupAndCarousel(value._id)}
                        />
                      </Tooltip>
                    </button>{" "}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PopupAndcarouselImageTable;
